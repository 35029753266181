const config = {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    BUCKET: 'vjt-notes-app-upload',
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://37ztjwvfw9.execute-api.us-west-2.amazonaws.com/dev',
    ASSESSMENTS_URL: 'https://vyi3xglow4.execute-api.us-west-2.amazonaws.com/dev',
    COMPANIES_URL: 'https://y5hpijekb6.execute-api.us-west-2.amazonaws.com/dev',
    DASHBOARDS_URL: 'https://szvj6gavuf.execute-api.us-west-2.amazonaws.com/dev',
    DOWNTIMES_REASONS_URL: 'https://cegy9wbm63.execute-api.us-west-2.amazonaws.com/dev',
    DOWNTIMES_EVENTS_URL: 'https://cbsw859e1b.execute-api.us-west-2.amazonaws.com/dev',
    INSPECTIONS_URL: 'https://vyi3xglow4.execute-api.us-west-2.amazonaws.com/dev',
    LINES_URL: 'https://qerhtda80d.execute-api.us-west-2.amazonaws.com/dev',
    OEE_URL: 'https://9qazqzahsh.execute-api.us-west-2.amazonaws.com/dev',
    PRODUCTS_URL: 'https://nrknl523hg.execute-api.us-west-2.amazonaws.com/dev',
    ROLES_URL: 'https://d3u7dbr0xk.execute-api.us-west-2.amazonaws.com/dev',
    SCHEDULES_URL: 'https://px17jx74g0.execute-api.us-west-2.amazonaws.com/dev',
    SHIFT_URL: 'https://bg6bjszpfg.execute-api.us-west-2.amazonaws.com/dev',
    SITES_URL: 'https://kcn3l76ncd.execute-api.us-west-2.amazonaws.com/dev',
    USERS_URL: 'https://gccvywxpki.execute-api.us-west-2.amazonaws.com/dev',
    NOTIFICATION_URL: 'https://r9k2q0egxc.execute-api.us-west-2.amazonaws.com/dev',
    CIRRUS_URL: 'https://dev.cirrusprint.net',
    //TEMPLATE_DESIGN_API_URL: 'https://localhost:5001',
    TEMPLATE_DESIGN_API_URL: 'https://designapi.dev.videojetcloud.com',
    SALESFORCE_URL: 'https://videojet--uat.sandbox.my.salesforce.com/',
    VRS_URL: 'https://vrs-dev.videojetcloud.com',
    VCCM_HELP_URL: 'http://help.dev.videojetcloud.com/',
    API_APP_URL: 'http://videojetcloud-customer-api-app-client.s3-website-us-west-2.amazonaws.com/',
    NGINX_HTTP_SERVER: 'dev-http-print.videojetcloud.com',
    NGINX_VNC_SERVER: 'dev-vnc-print.videojetcloud.com',
    NGINX_SSH_PORT: '443',
    NGINX_COOKIE_DOMAIN: 'videojetcloud.com',
    API_BASE_URL: 'https://9hrrx4jd9c.execute-api.us-west-2.amazonaws.com/dev'
  },
  appSync: {
    REGION: 'us-west-2',
    VRS_APPSYNC_URL: 'https://x2jjwr45nrcqfd3lxk4jwigobi.appsync-api.us-west-2.amazonaws.com/graphql',
    RES_APPSYNC_URL: 'https://6quhtkarvrekne4hpqhxkiqyeq.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_DEVICE_URL: 'https://x2jjwr45nrcqfd3lxk4jwigobi.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_REPORT_URL: 'https://ys3wdvsci5eivkdwrwhpxtp2wi.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_RES_URL: 'https://6quhtkarvrekne4hpqhxkiqyeq.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_COMPANY_URL: 'https://iau6medaxbf7vntqc6qilizagi.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_SITE_URL: 'https://sp4yos53qfe6fnjnatvvac66mi.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_SYSTEM_URL: 'https://z3cbnrro4rhqzaadgqgo7ebdva.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_USER_URL: 'https://pl3ma2xnhrgudcx4o7uzc64t5q.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_NOTIFICATION_URL: 'https://sb2u7jhubnaqnd3hhnngb2i4gq.appsync-api.us-west-2.amazonaws.com/graphql',
  },

  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_UqXT7H8oa',
    APP_CLIENT_ID: 'gtf6tfnf42bgdm19ppgirnstj',
    IDENTITY_POOL_ID: 'us-west-2:4820d61d-bc78-4d17-8272-64eb7eea7f1c',
    OATH_TOKEN_URL: 'https://vccmdev.videojetcloud.com/oauth2',
    SSO_API: 'https://vjcloudservices.auth.us-west-2.amazoncognito.com/authorize?identity_provider=VJHUBADFS&response_type=token&client_id=3aarms5h44ejke940nobm4srso&redirect_uri=https://vccm.dev.videojetcloud.com/showJWT',
  },
  settings: {
    CLIENT_VERSION: '7a02994f097c141eb8bdf33fcf702107c7e76ec5',
    UM_MODULE: 'VCCM',
    MF_PROJECT: '044b94d2-687e-4bca-96ce-d3241e9cd08c',
    VRS_VERSION: '2.9.95'
  },
};

export default config;
